// 新增/编辑工资条
<template>
    <el-dialog :close-on-click-modal="false"
               :title="searchForm.userName+'调薪记录'"
               :visible="true"
               width="1200px"
               :before-close="close">

        <salaryIncreaseRecord :userName="searchForm.userName"></salaryIncreaseRecord>

    </el-dialog>
</template>
<script>
import salaryIncreaseRecord from "../../salaryIncreaseRecord"; // 调薪记录
export default {
    name: "popSalaryRecord",
    components: {
        salaryIncreaseRecord,
    },
    props: {
        formData: {
            type: Object,
            default: new Object(),
        },
    },
    watch: {
        formData: {
            deep: true,
            immediate: true,
            handler: function (newVal) {
                newVal &&
                    (this.searchForm = this.commonJs.deepCopy(
                        Object.assign({}, this.searchForm, newVal)
                    ));
            },
        },
    },
    data() {
        return {
            searchForm: {
                userName:""
            },
        };
    },
    mounted() {

    },
    methods: {
        // 关闭弹窗
        close() {
            this.$emit("close");
        },
    },
    computed: {},
};
</script>
<style scoped>
</style>