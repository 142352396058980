// 调薪记录 // popChangeSalaryHistory
<template>
    <div class="popChangeSalaryHistory">
        <el-dialog
            title="调薪记录"
            :close-on-click-modal="false"
            :visible.sync="dialogVisible"
            :modal-append-to-body="true"
            :append-to-body="true"
            width="1000px"
            :before-close="close"
        >
            <div class="miniTable">
                <div class="emptyBg" v-if="!tableData.data.length"></div>
                <div
                    class="popItemBox"
                    v-for="(item, idx) in tableData.data"
                    :key="idx"
                >
                    <div class="title">
                        生效日期：{{
                            item.effectiveMonthStr
                        }}
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 调整日期：{{
                            item.adjustmentDate
                        }}
                    </div>
                    <div class="tableBox">
                        <div class="header">
                            <div class="col">薪资标准</div>
                            <div class="col">绩效工资</div>
                            <div class="col">补贴</div>
                            <div class="col">在职延续奖</div>
                            <div class="col">餐补</div>
                            <div class="col">其他</div>
                            <div class="col">扣减</div>
                        </div>
                        <div class="tableBody">
                            <div class="col">
                                {{ item.salaryStandard || "暂无" }}
                            </div>
                            <div class="col">
                                {{ item.salaryMerit || "暂无" }}
                            </div>
                            <div class="col">
                                {{ item.subsidy || "暂无" }}
                            </div>
                            <div class="col">
                                {{ item.duringBonus || "暂无" }}
                            </div>
                            <div class="col">
                                {{ item.salaryFood || "暂无" }}
                            </div>
                            <div class="col">
                                {{ item.salaryOther || "暂无" }}
                            </div>
                            <div class="col">
                                {{ item.otherDeductions || "暂无" }}
                            </div>
                        </div>
                    </div>
                    <el-row :gutter="15" class="info">
                        <el-col :span="8"
                            >养老保险：{{ item.endowmentInsuranceTotal }}（{{
                                item.endowmentInsuranceStaff
                            }}、{{ item.endowmentInsuranceCompany }}）</el-col
                        >
                        <el-col :span="8"
                            >医疗保险：{{ item.medicalInsuranceTotal }}（{{
                                item.medicalInsuranceStaff
                            }}、{{ item.medicalInsuranceCompany }}）</el-col
                        >
                        <el-col :span="8"
                            >失业保险：{{ item.unemploymentInsuranceTotal }}（{{
                                item.unemploymentInsuranceStaff
                            }}、{{
                                item.unemploymentInsuranceCompany
                            }}）</el-col
                        >
                        <el-col :span="8"
                            >工伤保险：{{
                                item.employmentInjuryInsuranceTotal
                            }}（{{ item.employmentInjuryInsuranceStaff }}、{{
                                item.employmentInjuryInsuranceCompany
                            }}）</el-col
                        >
                        <el-col :span="8"
                            >生育保险：{{ item.birthInsuranceTotal }}（{{
                                item.birthInsuranceStaff
                            }}、{{ item.birthInsuranceCompany }}）</el-col
                        >
                        <el-col :span="8"
                            >公积金：{{ item.accumulationFundTotal }}（{{
                                item.accumulationFundStaff
                            }}、{{ item.accumulationFundCompany }}）</el-col
                        >
                        <el-col :span="8"
                            >大病医疗保险：{{
                                item.seriousSickInsurance
                            }}</el-col
                        >
                    </el-row>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { Loading } from "element-ui";
import API from "@/api/finance.js";
import { mapGetters } from "vuex";
export default {
    name: "popChangeSalaryHistory",

    props: {
        // 编辑操作时带过来的  id  以及pwd
        detailParms: {},
        // 后端的publick 用于加密钥匙
        publicKey: {
            type: String,
            default: null,
        },
    },

    components: {},

    data() {
        return {
            // 调薪记录列表
            tableData: {
                data: [],
            },
            dialogVisible: true,
            detailsData: null, // 查询详情所需
            decryFieldArr: [
                "salaryStandard",
                "salaryMerit",
                "salaryFood",
                "salaryOther",
                "otherDeductions",
                "endowmentInsuranceTotal",
                "endowmentInsuranceStaff",
                "endowmentInsuranceCompany",
                "medicalInsuranceTotal",
                "medicalInsuranceStaff",
                "medicalInsuranceCompany",
                "unemploymentInsuranceTotal",
                "unemploymentInsuranceStaff",
                "unemploymentInsuranceCompany",
                "employmentInjuryInsuranceTotal",
                "employmentInjuryInsuranceStaff",
                "employmentInjuryInsuranceCompany",
                "birthInsuranceTotal",
                "birthInsuranceStaff",
                "birthInsuranceCompany",
                "accumulationFundTotal",
                "accumulationFundStaff",
                "accumulationFundCompany",
                "seriousSickInsurance",
                "subsidy",
                "duringBonus",
            ], // 需要解密/加密的字段
        };
    },

    created() {},

    mounted() {
        // 获取调薪记录列表
        this.getList();
    },

    methods: {
        // 获取调薪记录列表
        getList() {
            let loading = Loading.service({
                target: document.querySelector(".controlList"),
            });
            let data = Object.assign({}, this.detailParms, {
                pwd: this.commonJs.rsaEncryption(
                    this.salaryPwd,
                    this.publicKey
                ),
            });
            API.getAdjustSalaryHistory(data)
                .then((res) => {
                    loading.close();
                    this.tableData.data = res.content.records.map((v) => {
                        return Object.assign({}, v, this.setDecryptField(v));
                    });
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 关闭弹窗
        close() {
            this.$emit("close");
        },
        /* 对关键数据进行解密 */
        setDecryptField(v) {
            return Object.assign(
                {},
                v,
                this.commonJs.decryMapData(
                    this.commonJs.deepCopy(v),
                    this.decryFieldArr
                )
            );
        },
    },

    computed: {
        ...mapGetters(["salaryPwd"]),
    },

    watch: {},
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
    max-height: 600px;
    padding-left: 30px;
    overflow-y: auto;
}
.tableBox {
    margin-bottom: 10px;
    .header {
        display: flex;
        .col {
            flex: 1;
            font-weight: 600;
        }
    }
    .tableBody {
        display: flex;
    }
    .col {
        flex: 1;
        line-height: 40px;
        text-align: center;
        font-size: 16px;
        border-right: 1px solid #eee;
        border-bottom: 1px solid #eee;
    }
}
.info {
    padding: 15px;
    ::v-deep .el-col {
        margin-bottom: 10px;
    }
}
</style>
