// 薪酬档案列表
<template>
    <div class="smallInput">
        <div class="searchBar ofh searchBox">
            <el-form
                :model="searchForm"
                ref="searchForm"
                class="fll searchForm"
                :inline="true"
                size="normal"
            >
                <el-form-item label="姓名" prop="name">
                    <el-input
                        v-model="searchForm.name"
                        placeholder="请输入姓名"
                        clearable
                    />
                </el-form-item>

                <el-form-item label="部门" prop="dingTalkDept">
                    <el-input
                        v-model="searchForm.dingTalkDept"
                        placeholder="请输入部门"
                        clearable
                    />
                </el-form-item>

                <template v-if="type !== 'OTHER'">
                    <el-form-item label="职位" prop="job">
                        <el-input
                            v-model="searchForm.job"
                            placeholder="请输入职位"
                            clearable
                        />
                    </el-form-item>

                    <el-form-item label="员工状态" prop="workerStatus">
                        <el-select
                            v-model="searchForm.workerStatus"
                            placeholder="请选择"
                        >
                            <el-option
                                v-for="(item, index) in userOpt"
                                :key="index"
                                :label="item.label"
                                :value="item.code"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                </template>

                <el-form-item>
                    <el-button type="primary" @click="search">搜索</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button type="" @click="resetSearchForm">清除</el-button>
                </el-form-item>
            </el-form>
        </div>

        <div class="listBox salaryTableBox">
            <div class="tableBox">
                <el-table
                    :data="tableData"
                    border
                    v-loading="isloading"
                    :height="tableHeight"
                    stripe
                >
                    <el-table-column type="index" width="50" label="序号">
                    </el-table-column>
                    <el-table-column prop="userName" width="120" label="姓名">
                    </el-table-column>
                    <el-table-column
                        prop="dingTalkDept"
                        width="120"
                        label="部门"
                    >
                    </el-table-column>
                    <el-table-column prop="job" width="140" label="职位">
                    </el-table-column>
                    <el-table-column
                        prop="workerStatus.text"
                        width="140"
                        label="员工状态"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="payTime"
                        width="140"
                        label="最近薪资日期"
                    >
                    </el-table-column>

                    <el-table-column
                        prop="salaryStandard"
                        width="auto"
                        minWidth="150px"
                        label="薪资标准（元）"
                    >
                        <template slot-scope="scope">
                            {{
                                showMoney && showMoneyPwd
                                    ? commonJs.wageAbs(scope.row.salaryStandard)
                                    : "******"
                            }}
                        </template>
                    </el-table-column>

                    <el-table-column
                        prop="socialSecurity"
                        width="auto"
                        minWidth="150px"
                        label="社保标准（元）"
                    >
                        <template slot-scope="scope">
                            {{
                                showMoney && showMoneyPwd
                                    ? commonJs.wageAbs(scope.row.socialSecurity)
                                    : "******"
                            }}
                        </template>
                    </el-table-column>

                    <el-table-column
                        prop="accumulationFund"
                        width="auto"
                        minWidth="150px"
                        label="公积金标准（元）"
                    >
                        <template slot-scope="scope">
                            {{
                                showMoney && showMoneyPwd
                                    ? commonJs.wageAbs(
                                          scope.row.accumulationFund
                                      )
                                    : "******"
                            }}
                        </template>
                    </el-table-column>

                    <el-table-column
                        label="操作"
                        fixed="right"
                        align="center"
                        width="220"
                    >
                        <template slot-scope="scope">
                            <el-link
                                type="primary"
                                @click="openpopAdjustSalary(scope.row)"
                                >调整薪资</el-link
                            >
                            <el-link
                                type="primary"
                                @click="
                                    openpopAdjustSalary(scope.row, 'details')
                                "
                                >详情</el-link
                            >
                            <el-link
                                type="primary"
                                @click="toRecordDetails(scope.row)"
                                >发薪记录</el-link
                            >
                            <el-popconfirm
                                confirm-button-text="删除"
                                cancel-button-text="取消"
                                icon="el-icon-info"
                                icon-color="red"
                                @confirm="del(scope.row)"
                                title="真的要删除吗？"
                                v-if="activeName === 'OTHER'"
                            >
                                <el-link type="danger" slot="reference"
                                    >删除</el-link
                                >
                            </el-popconfirm>
                        </template>
                    </el-table-column>
                </el-table>
                <!-- 分页 -->
                <Pagination
                    :page="searchForm.page"
                    :limit="searchForm.size"
                    :total="pageTotal"
                    @pagination="paginationChange"
                />
            </div>
        </div>

        <!-- 调薪记录 -->
        <popSalaryRecord
            v-if="popSalaryRecordVisivle"
            @close="closeDialog"
            :formData="salaryData"
        ></popSalaryRecord>
        <!-- 调整薪资 -->
        <pop-adjust-salary
            v-if="popAdjustSalaryVisible"
            key="popAdjustSalary"
            :detailParms="detailParm"
            @refresh="ayncSuccess"
            :salaryOperationType="editOrDetails"
            @close="closepopAdjustSalary"
        ></pop-adjust-salary>
    </div>
</template>

<script>
import PopAdjustSalary from "../../../salary/components/popAdjustSalary";
import humanResourcesManageApi from "@/api/humanResourcesManageApi.js";
import Pagination from "@/components/Pagination"; // 分页
import popSalaryRecord from "./componets/popSalaryRecord"; // 调薪记录弹框
import rsa from "@/utils/encryption/rsa"; // RSA解密
import { Loading } from "element-ui";
import API from "@/api/humanResourcesManageApi.js";
export default {
    name: "comSalaryTable",
    props: {
        //人员状态
        type: {
            type: String,
            default: "",
        },

        //显示金额
        showMoney: {
            type: Boolean,
        },

        //触发更新
        refresh: {
            type: Number,
        },

        //显示薪酬的密码传递更新
        showMoneyPwdOpt: {},

        //当前激活的tab
        activeName: {},
    },

    watch: {
        //导入数据后的刷新
        refresh: {
            deep: true,
            immediate: true,
            handler: function(newVal) {
                newVal && this.activeList();
            },
        },

        //显示薪酬的密码传递更新
        showMoneyPwdOpt: {
            handler: function(newval) {
                if (newval) {
                    this.showMoneyPwd = newval.pwd;
                    this.getList().then(() => {
                        this.$emit("updateShowMoney", true);
                    });
                }
            },
        },
    },

    components: {
        PopAdjustSalary,
        Pagination,
        popSalaryRecord,
    },

    data() {
        return {
            dialogVisible: false,
            popSalaryRecordVisivle: false, //调薪记录列表
            salaryData: {}, //调薪记录数据
            fontRsaKey: {}, //生成 加密 公私钥
            showMoneyPwd: null, //显示薪酬的密码
            searchForm: {
                name: "", //用户名称
                dingTalkDept: "", //钉钉部门名称
                job: "", //职位
                workerStatus: "", //员工状态
                page: 1,
                size: 20,
            },
            userOpt: this.commonJs
                .getSelectByType("WorkerStatus", "userOpt", this)
                .then((respData) => this.filterUserStatus(respData)),
            pageTotal: 0,
            isloading: false,
            rowData: null,
            tableData: [],
            rsa: rsa,
            editOrDetails: "edit", // 是查看还是编辑
            popAdjustSalaryVisible: false, // 调整薪资弹窗显示
        };
    },

    created() {},

    mounted() {
        // this.type === 'LEAVE' ? setTimeout(() => {
        this.entryRsaKey();
        // }, 2000) : this.entryRsaKey();
        this.getList();
    },

    methods: {
        /* 获取列表 */
        getList(searchForm = this.searchForm) {
            this.isloading = true;
            let menuId = window.localStorage.getItem("routerId"); //当前的菜单id  接口做权限过滤使用
            return new Promise((resolve) => {
                let data = Object.assign({}, searchForm, {
                    workerStatus: this.getWorkerStatusByType(
                        searchForm.workerStatus
                    ),
                    publicKey: this.serverPublicKey,
                    pwd: this.commonJs.rsaEncryption(
                        this.showMoneyPwd,
                        this.serverPublicKey
                    ),
                    isOther: this.type === "OTHER" ? true : false,
                    frontPublicKey: this.fontRsaKey.publicKey,
                });
                humanResourcesManageApi
                    .getSalaryTable(data, menuId)
                    .then((res) => {
                        this.tableData = res.content.records.map((v) => {
                            return Object.assign({}, v, {
                                salaryStandard: rsa.rsaDecrypt(
                                    v.salaryStandard
                                ), //薪资标准（元）
                                socialSecurity: rsa.rsaDecrypt(
                                    v.socialSecurity
                                ), //社保标准（元）
                                accumulationFund: rsa.rsaDecrypt(
                                    v.accumulationFund
                                ), //公积金标准（元）
                            });
                        });
                        this.pageTotal = res.content.total;
                        this.showMoneyPwd
                            ? this.$store.commit(
                                  "SET_SEESALARYPWD",
                                  this.showMoneyPwd ||
                                      this.$store.state.user.seeSalaryPwd
                              )
                            : "";
                        this.$emit("contractTypeNum", {
                            type: this.type,
                            num: this.pageTotal,
                        }); //设置总数
                        resolve();
                        this.isloading = false;
                    })
                    .catch(() => {
                        this.isloading = false;
                    });
            });
        },

        /*  获取服务款公钥  、  生成加解密公钥、私钥 */
        entryRsaKey() {
            this.$store.dispatch("getSalaryKey").then((v) => {
                this.fontRsaKey = v;
                this.$store.dispatch("GetPublicKey").then((v) => {
                    //后端生成公钥
                    this.serverPublicKey = v.content;
                    this.showMoneyPwd = this.$store.state.user.seeSalaryPwd;
                    this.getList();
                });
            });
        },

        //查看详情
        details(e) {
            this.goDetrail({
                data: JSON.stringify(Object.assign({}, e)),
            });
        },
        // 调整薪资 打开弹框
        openpopAdjustSalary(row, type = "edit") {
            this.detailParm = Object.assign({}, this.detailParm, {
                userId: row.userId,
                pwd: this.showMoneyPwdOpt ? this.showMoneyPwdOpt.pwd : null,
            });
            if (!this.showMoneyPwdOpt) {
                this.$emit("showInputPwd", true);
                return;
            }
            this.editOrDetails = type;
            this.popAdjustSalaryVisible = true;
        },
        // 调整薪资 关闭弹框
        closepopAdjustSalary() {
            this.popAdjustSalaryVisible = false;
        },

        //查看当前选中用户调薪记录
        seeRecord(data) {
            this.salaryData = data;
            this.popSalaryRecordVisivle = true;
        },

        // 前往详情页
        toRecordDetails(data) {
            let query = {
                id: data.userId,
                isOther: !data.workerStatus,
            };
            query.userName = data.userName || "无";
            query.dept = data.dingTalkDept || "无";
            this.$router.push({
                path: "/humanResourcesManage/compensationDetail",
                query: query,
            });
        },
        // 删除其他人员
        del(data) {
            let loading = Loading.service({
                target: document.querySelector(".salaryTableBox"),
            });
            API.delOtherSalary(data.userId)
                .then(() => {
                    loading.close();
                    this.$message({ message: "操作成功！", type: "success" });
                    this.getList();
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 搜索
        search() {
            this.defaultSearch();
        },

        //清空筛选条件
        resetSearchForm() {
            this.$nextTick(() => {
                if (this.$refs["searchForm"] !== undefined) {
                    this.$refs["searchForm"].resetFields();
                    // 查询列表
                    this.getList();
                }
            });
        },

        // 分页改变
        paginationChange(e) {
            this.getList(Object.assign(this.searchForm, e));
        },

        // 新增或編輯系統彈框 关闭
        closeSystemDialog() {
            this.dialogVisible = false;
            this.defaultSearch();
        },

        // 默认查询第一页
        defaultSearch() {
            this.searchForm.page = 1;
            this.getList();
        },

        // 表格的編輯操作
        editTable(e) {
            this.rowData = e;
            this.dialogVisible = true;
        },

        //过滤离职/在职状态
        filterUserStatus(data) {
            if (this.type === "PROBATION,REGULAR,WAIT_LEAVE") {
                //在职
                this.userOpt = data.filter(
                    (v) => v.label !== "离职" || v.label === "全部"
                );
            } else {
                //离职
                this.userOpt = data.filter((v) => v.label === "离职");
            }
        },

        //根据当前离职在职状态 更改用户状态参数
        getWorkerStatusByType(workerStatus) {
            return workerStatus
                ? workerStatus.split(",")
                : this.type.split(",");
        },

        closeDialog() {
            this.popSalaryRecordVisivle = false;
        },

        /* 激活列表拉取 */
        activeList() {
            this.type === "LEAVE"
                ? setTimeout(() => {
                      this.entryRsaKey();
                  }, 1500)
                : this.entryRsaKey();
        },
    },

    computed: {
        tableHeight() {
            return this.commonJs.getTableHeight({ pageOtherHeight: 305 });
        },
    },
};
</script>

<style lang="scss">
.smallInput .searchForm .el-input {
    width: 150px !important;
}
</style>
<style scoped>
.searchBox {
    margin-bottom: 0px;
    padding-bottom: 0px;
}
</style>
