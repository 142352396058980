// 调薪记录列表
<template>
    <div class="baseBg" :style="{'min-height':!showMe ? 'calc(100vh - 88px)' : 'auto'}">
        <div class="searchBar ofh breakSearch">
            <el-form :model="searchForm"
                     ref="searchForm"
                     class="fll fullRow"
                     :inline="true"
                     size="normal">
                <el-form-item v-if="!showMe" label="姓名"
                              prop="name">
                    <el-input v-model="searchForm.name"
                              placeholder="请输入姓名"
                              clearable />
                </el-form-item>

                <el-form-item v-if="!showMe" label="部门"
                              prop="dingTalkDept">
                    <el-input v-model="searchForm.dingTalkDept"
                              placeholder="请输入部门"
                              clearable />
                </el-form-item>

                <el-form-item v-if="!showMe" label="职位"
                              prop="job">
                    <el-input v-model="searchForm.job"
                              placeholder="请输入职位"
                              clearable />
                </el-form-item>

                <el-form-item v-if="!showMe" label="调薪日期"
                              prop="date">
                    <el-date-picker v-model="searchForm.date"
                                    type="monthrange"
                                    value-format="yyyy-MM"
                                    format="yyyy-MM"
                                    range-separator="至"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期">
                    </el-date-picker>
                </el-form-item>

                <el-form-item v-if="!showMe">
                    <el-button type="primary"
                               @click="search">搜索</el-button>
                </el-form-item>

                <el-form-item v-if="!showMe">
                    <el-button type=""
                               @click="resetSearchForm">清除</el-button>
                </el-form-item>

                <el-form-item class="flr">
                    <el-button type="primary "
                               @click="showSalary">
                               {{showMoney ? '隐藏' : '显示'}}薪资<countdown @countDowmEnd="countDowmEnd" v-if="showMoney"></countdown>
                    </el-button>
                </el-form-item>

            </el-form>
        </div>

        <div class="listBox">
            <div class="tableBox">
                <el-table :data="tableData"
                          border
                          v-loading="isloading"
                          :height="!showMe ? tableHeight : '300px'"
                          stripe>

                    <el-table-column type="index"
                                     align="center"
                                     width="50"
                                     label="序号">
                    </el-table-column>
                    <el-table-column prop="userName"
                                     width="200"
                                     label="姓名">
                    </el-table-column>
                    <el-table-column prop="dingTalkDept"
                                     label="部门">
                    </el-table-column>
                    <el-table-column prop="job"
                                     width="200"
                                     label="职位">
                    </el-table-column>
                    <el-table-column prop="oldSalary"
                                     width="200"
                                     label="原薪资（元）">
                        <template slot-scope="scope">
                            {{showMoney ? commonJs.wageAbs(scope.row.oldSalary) : "******"}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="newSalary"
                                     width="200"
                                     label="变更后薪资（元）">
                        <template slot-scope="scope">
                            {{showMoney ? commonJs.wageAbs(scope.row.newSalary) : "******"}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="changeTime"
                                     width="200"
                                     label="调薪日期">
                    </el-table-column>

                </el-table>
                <!-- 分页 -->
                <Pagination :page="searchForm.page"
                            :limit="searchForm.size"
                            :total="pageTotal"
                            @pagination="paginationChange" />
            </div>
        </div>

         <!-- 输入密码 -->
        <popPwd v-if="showInputPwd" @close="close" @updatePwd="updatePwd"></popPwd>
    </div>
</template>

<script>
import humanResourcesManageApi from "@/api/humanResourcesManageApi.js";
import Pagination from "@/components/Pagination"; // 分页
import countdown from "@/components/countdown"; // 倒计时
import rsa from "@/utils/encryption/rsa"; // RSA解密
import popPwd from "./compensation/componets/popPwd"; // 填写密码
export default {
    name: "salaryIncreaseRecord",
    props: {
        userName:{}
    },

    components: {
        Pagination,
        countdown,
        popPwd
    },

    data() {
        return {
            searchForm: {
                name: "", //人员名称
                job: "", //职位
                dingTalkDept: "", //部门
                date: ["", ""], //调薪日期
                page: 1,
                size: 20,
            },
            //部门选择默认数据
            pageTotal: 0,
            isloading: false,
            tableData: [],
            showMoney: false,
            showInputPwd: false, //是否显示输入密码弹框
            showMoneyPwd:null,// 显示薪酬的密码
            rsa,
            showMe:false,//是否是只查看自己  不要筛选条件
        };
    },

    created() {},

    mounted() {
        this.isGoRouter();
        // this.search();
        this.entryRsaKey();
    },

    methods: {

        //是否为携带参数 路由跳转
        isGoRouter(){
            let query = this.$route.query;
            if(query && JSON.stringify(query) !== '{}'){
                this.searchForm.name = query.userName;
            }
        },

        /* 获取列表 */
        getList(searchForm = this.searchForm) {
            this.isloading = true;
            return new Promise((resolve) => {
                humanResourcesManageApi
                    .changeSalaryList(
                        Object.assign({}, searchForm, {
                            changeStartTime:searchForm.date ? searchForm.date[0] : "",
                            changeEndTime:searchForm.date ? searchForm.date[1] : "",
                            publicKey:this.serverPublicKey,
                            // pwd:this.commonJs.rsaEncryption(this.$store.state.user.seeSalaryPwd || this.showMoneyPwd,this.serverPublicKey),
                            pwd:this.commonJs.rsaEncryption(this.showMoneyPwd ,this.serverPublicKey),
                            frontPublicKey:this.fontRsaKey.publicKey
                        })
                    )
                    .then((res) => {
                        this.isloading = false;
                        this.tableData = res.content.records;
                        this.tableData = res.content.records.map(v=>{
                            return Object.assign({},v,{
                                oldSalary:rsa.rsaDecrypt(v.oldSalary), //原薪资（元）
                                newSalary:rsa.rsaDecrypt(v.newSalary), //变更后薪资（元）
                            })
                        });
                        this.pageTotal = res.content.total;
                        this.$store.commit("SET_SEESALARYPWD",this.showMoneyPwd || this.$store.state.user.seeSalaryPwd);
                        resolve();
                    })
                    .catch(() => {
                        this.isloading = false;
                    });
            })
        },

        /*  获取服务款公钥  、  生成加解密公钥、私钥 */
        entryRsaKey(){
            this.$store.dispatch("getSalaryKey").then(v=>{
                this.fontRsaKey = v;
                this.$store.dispatch("GetPublicKey").then(v=>{
                    //后端生成公钥
                    this.serverPublicKey = v.content;
                    this.search();
                });
            })
        },

        // 搜索
        search() {
            this.defaultSearch();
        },

        //清空筛选条件
        resetSearchForm() {
            this.$nextTick(() => {
                if (this.$refs["searchForm"] !== undefined) {
                    this.$refs["searchForm"].resetFields();
                    // 查询列表
                    this.getList();
                }
            });
        },

        // 分页改变
        paginationChange(e) {
            this.getList(Object.assign(this.searchForm, e));
        },

        // 默认查询第一页
        defaultSearch() {
            this.searchForm.page = 1;
            this.getList();
        },

        //显示金额
        showSalary() {
            /* 针对每次都让输入密码 */
            this.showMoney ? this.showMoney = false : this.showInputPwd = true;

            /*  是否针对密码输入正确后就不需要输入  */
            // if(this.seeSalaryPwd){
            //     this.showMoney = !this.showMoney;
            //     this.showMoneyPwd = this.seeSalaryPwd;
            // } else {
            //     this.showInputPwd = true;
            // }
        },

        countDowmEnd() {
            this.showSalary();
        },

        /* 关闭弹框 */
        close(){
            this.showInputPwd = false;
        },

        /* 密码确认 */
        updatePwd(opt){
            this.showMoneyPwd = opt.pwd;
            this.getList().then(()=>{
                this.showMoney = true;
            });
        },
    },

    computed: {
        tableHeight() {
            return this.commonJs.getTableHeight({ pageOtherHeight: 245 });
        },

        //查询薪酬的密码
        seeSalaryPwd(){
            return this.$store.state.user.seeSalaryPwd
        }
    },

    watch: {
        //用户姓名
        userName: {
            deep: true,
            immediate: true,
            handler: function (newVal) {
                if(newVal){
                    this.searchForm.name = newVal;
                    this.showMe = true;
                    this.search();
                }

            },
        },
    },
};
</script>

<style lang='scss' scoped>
</style>
