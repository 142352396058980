// 薪酬档案
<template>
    <div class="baseBg reserveProject">
        <div class="listBox mainWhiteBg"
             style="position: relative;">

            <!-- <el-button type=""
                       class="topBtn"
                       v-loading="downTempLoading"
                       :style="{right: showMoney ? '290px' : '250px'}"
                       @click="downTemplate"> 下载模板
            </el-button>

            <div class="controlBtn downAndUpload flr">
                <el-upload class="upload-demo"
                           name="file"
                           :headers="configHeader"
                           :action="action"
                           :on-success="uploadSuccess"
                           :before-upload="beforeUpload"
                           :on-error="uploadError"
                           multiple
                           :show-file-list="false">
                    <el-button type="success topBtn"
                               v-loading="importLoading"
                               :style="{right: showMoney ? '170px' : '130px'}">导入数据</el-button>
                </el-upload>
            </div> -->
            <el-button type="warning topBtn"
                       v-if="activeName === 'OTHER'"
                       :style="{right: showMoney ? '165px' : '115px'}"
                       @click="openpopAdjustSalary">新增其他人员</el-button>

            <el-button type="primary topBtn"
                       :style="{right: '0'}"
                       @click="showSalary"> {{showMoney ? '隐藏' : '显示'}}薪资
                <countdown @countDowmEnd="countDowmEnd"
                           v-if="showMoney"></countdown>
            </el-button>
            <el-tabs v-model="activeName">
                <el-tab-pane :name="item.type"
                             v-for="(item,index) in tabList"
                             :key="index">
                    <div class="tabsLabel"
                         slot="label">
                        <el-badge :type="activeName == item.type ? 'primary' : 'warning'"
                                  :value="item.value"
                                  class="item">
                            {{item.title}}
                        </el-badge>
                    </div>
                </el-tab-pane>
            </el-tabs>

            <template v-if="activeName === 'PROBATION,REGULAR,WAIT_LEAVE'">
                <comSalaryTable type="PROBATION,REGULAR,WAIT_LEAVE"
                                key="onlineTable"
                                :showMoney="showMoney"
                                :activeName="activeName"
                                :refresh="refresh"
                                :showMoneyPwdOpt="showMoneyPwdOpt"
                                @updateShowMoney="updateShowMoney"
                                @showInputPwd="showInputPwdPop"
                                @contractTypeNum="updateNum"></comSalaryTable>
            </template>

            <template v-if="activeName === 'LEAVE'">
                <comSalaryTable type="LEAVE"
                                key="leaveTable"
                                :showMoney="showMoney"
                                :activeName="activeName"
                                :refresh="refresh"
                                :showMoneyPwdOpt="showMoneyPwdOpt"
                                @updateShowMoney="updateShowMoney"
                                @showInputPwd="showInputPwdPop"
                                @contractTypeNum="updateNum"></comSalaryTable>
            </template>

            <template v-if="activeName === 'OTHER'">
                <comSalaryTable type="OTHER"
                                key="otherTable"
                                :showMoney="showMoney"
                                :activeName="activeName"
                                :refresh="refresh"
                                :showMoneyPwdOpt="showMoneyPwdOpt"
                                @updateShowMoney="updateShowMoney"
                                @showInputPwd="showInputPwdPop"
                                @contractTypeNum="updateNum"></comSalaryTable>
            </template>
        </div>

        <!-- 输入密码 -->
        <popPwd v-if="showInputPwd"
                tip="为保障信息安全，本模块需要需要您确认密码后才可以进行其他操作！"
                @close="close"
                @updatePwd="updatePwd"></popPwd>

        <!-- 新增其他人员 -->
        <pop-adjust-salary v-if="popAdjustSalaryVisible"
                           :detailParms="detailParm"
                           @refresh="ayncSuccess"
                           salaryOperationType="other"
                           @close="closepopAdjustSalary"></pop-adjust-salary>
    </div>

</template>

<script>
import PopAdjustSalary from "../../../salary/components/popAdjustSalary";
// import baseinfoManage from "@/api/baseinfoManage.js";
import countdown from "@/components/countdown"; // 倒计时
import popPwd from "./componets/popPwd"; // 填写密码
import comSalaryTable from "./comSalaryTable";
import { Loading } from "element-ui";
import API from "@/api/finance.js";
export default {
    name: "compensationFile",
    props: [],

    components: {
        PopAdjustSalary,
        // Pagination,
        comSalaryTable,
        countdown,
        popPwd,
    },

    data() {
        return {
            activeName: "PROBATION,REGULAR,WAIT_LEAVE",
            dialogVisible: false,
            downTempLoading: false, //下载模板
            showMoney: false, //是否显示薪资
            showInputPwd: false, //是否显示输入密码弹框
            showMoneyPwdOpt: null, // 显示薪酬的密码信息
            detailParm: null, //查看详情时携带的数据 	 id:工资表id  pwd:	显示薪资的密码
            tabList: [
                {
                    type: "PROBATION,REGULAR,WAIT_LEAVE",
                    value: "0",
                    title: "在职",
                },
                {
                    type: "LEAVE",
                    value: "0",
                    title: "离职",
                },
                {
                    type: "OTHER",
                    value: "0",
                    title: "其他",
                },
            ], //员工状态tab
            refresh: 0, //同步数据后需要更新  不为0就更新
            importLoading: false,
            popAdjustSalaryVisible: false, // 新增其他人员弹窗显示
        };
    },

    created() {},

    mounted() {
        //设置各类型数量
        this.getCount();
    },

    methods: {
        //设置各类型数量
        getCount() {
            let loading = Loading.service({
                target: document.querySelector(".tableBox"),
            });
            API.getSalaryCount()
                .then((res) => {
                    loading.close();
                    let data = {
                        在职: res.content.activeCount,
                        离职: res.content.leaveCount,
                        其他: res.content.otherCount,
                    };
                    this.tabList.forEach((item) => {
                        item.value = data[item.title];
                    });
                })
                .catch(() => {
                    loading.close();
                });
        },

        //显示金额
        showSalary() {
            /* 针对每次都让输入密码 */
            this.showMoney
                ? (this.showMoney = false)
                : (this.showInputPwd = true);

            /*  是否针对密码输入正确后就不需要输入  */
            // if (this.seeSalaryPwd) {
            //     this.showMoney = !this.showMoney;
            //     this.showMoneyPwd = this.seeSalaryPwd;
            // } else {
            //     this.showInputPwd = true;
            // }
        },

        //员工花名册同步成功
        ayncSuccess() {
            this.refresh = Math.random();
            this.$forceUpdate();
        },

        //倒计时结束
        countDowmEnd() {
            this.showSalary();
        },

        //上传成功
        uploadSuccess(e) {
            this.importLoading = false;
            if (e.code !== 200) {
                return this.$message({
                    message: e.message || "网络错误，请稍后重试。",
                    type: "error",
                });
            }
            this.$msgbox({
                title: "",
                message: this.getUploadTisTem(e.content.errorNum > 0, e),
                showCancelButton: true,
                confirmButtonText:
                    e.content.errorNum > 0 ? "下载失败原因" : "确认",
                cancelButtonText: "取消",
            }).then(() => {
                //错误 需要下载
                e.content.errorNum > 0
                    ? this.commonJs.fileDownLoadByid(e.content.importFileId)
                    : "";
            });
            this.ayncSuccess();
        },

        //获取上传后的提醒模板
        getUploadTisTem(boolean, e) {
            const h = this.$createElement;
            //正常模板
            let successTpl = h("p", null, [
                h(
                    "i",
                    {
                        class: "el-icon-success",
                        style: "color: #00B600;font-size:18px",
                    },
                    ""
                ),
                h(
                    "span",
                    { style: "color: #000;font-size:16px;margin-left:10px" },
                    "导入成功 "
                ),
                h("p", { style: "margin-top:15px" }, [
                    h(
                        "span",
                        { style: "color: #000;font-size:14px;" },
                        "上传成功 "
                    ),
                    h(
                        "span",
                        { style: "color: #00B600;font-size:14px;" },
                        e.content.rightNum + "条"
                    ),
                    h(
                        "span",
                        { style: "color: #000;font-size:14px;" },
                        "数据 "
                    ),
                ]),
            ]);
            //错误模板
            let errorTpl = h("p", null, [
                h(
                    "i",
                    {
                        class: "el-icon-question",
                        style: "color: #FFBD26;font-size:20px",
                    },
                    ""
                ),
                h(
                    "span",
                    { style: "color: #000;font-size:16px;margin-left:10px" },
                    "是否查看失败原因 "
                ),
                h("p", { style: "margin-top:15px" }, [
                    h(
                        "span",
                        { style: "color: #000;font-size:14px;" },
                        "上传成功 "
                    ),
                    h(
                        "span",
                        { style: "color: #00B600;font-size:14px;" },
                        e.content.rightNum + "条 "
                    ),
                    h(
                        "span",
                        { style: "color: #000;font-size:14px;" },
                        "数据 "
                    ),
                ]),
                h("p", { style: "margin-top:10px" }, [
                    h(
                        "span",
                        { style: "color: #000;font-size:14px;" },
                        "上传失败 "
                    ),
                    h(
                        "span",
                        { style: "color: #FF3F2B;font-size:14px;" },
                        e.content.errorNum + "条 "
                    ),
                    h(
                        "span",
                        { style: "color: #000;font-size:14px;" },
                        "数据 "
                    ),
                ]),
            ]);
            return boolean ? errorTpl : successTpl;
        },

        //上传之前
        beforeUpload(file) {
            if (file.name.indexOf("xls") < 0 || file.name.indexOf("xlsx") < 0) {
                this.$message({
                    message: "文件格式错误，请检查后重新上传！",
                    type: "error",
                });
                return false;
            }
            let maxBit = 100 * 1024 * 1024; //目前上限100M文件
            if (file.size > maxBit) {
                this.$message({
                    message: "超过文件上传上限，最大100M",
                    type: "warning",
                });
                return false;
            }
            this.importLoading = true;
        },

        //上传失败
        uploadError(e) {
            this.$message({
                message: e.message || "网络错误，请稍后重试。",
                type: "error",
            });
            this.importLoading = false;
        },

        //下载模板
        downTemplate() {
            this.downTempLoading = true;
            this.commonJs
                .getFileList("salaryImportFile")
                .then((v) => {
                    this.commonJs.fileDownLoad({
                        id: v[0].id,
                        name: v[0].name,
                    });
                    this.downTempLoading = false;
                })
                .catch(() => (this.downTempLoading = false));
        },

        /* 关闭弹框 */
        close() {
            this.showInputPwd = false;
        },

        /* 密码确认 */
        updatePwd(opt) {
            // 保存密码到vuex
            this.$store.commit("SET_SALARYPWD", opt.pwd || null);
            this.showMoneyPwdOpt = opt;
        },

        /* 更新是否显示薪资 */
        updateShowMoney(boolean) {
            this.showMoney = boolean;
        },

        /* 显示弹框 */
        showInputPwdPop(boolean) {
            this.showInputPwd = boolean;
        },
        // 新增其他人员 打开弹框
        openpopAdjustSalary() {
            this.detailParm = {
                pwd: this.showMoneyPwdOpt ? this.showMoneyPwdOpt.pwd : null,
            };
            if (!this.showMoneyPwdOpt) {
                return (this.showInputPwd = true);
            }
            this.popAdjustSalaryVisible = true;
        },
        // 新增其他人员 关闭弹框
        closepopAdjustSalary() {
            this.popAdjustSalaryVisible = false;
        },
        // 更新tab数值
        updateNum(data) {
            this.tabList.forEach((item) => {
                if (item.type === data.type) {
                    item.value = data.num;
                }
            });
            console.log(data);
        },
    },
    computed: {
        configHeader() {
            return { "access-token": this.commonJs.getCookie("ERPToken") };
        },

        action() {
            return window.configPath.baseUrl + "/salary/importExcel";
        },

        //查询薪酬的密码
        seeSalaryPwd() {
            return this.$store.state.user.seeSalaryPwd;
        },
    },

    watch: {},
};
</script>

<style lang='scss' scoped>
.reserveProject ::v-deep .el-tabs__nav-scroll {
    overflow: visible;
}
.reserveProject ::v-deep .el-tabs__nav-wrap {
    overflow: visible;
    height: 40px;
}
.topBtn {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 9;
}
.reserveProject .topBtn:nth-child(1) {
    right: 130px;
}
</style>
