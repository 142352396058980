// 调整薪资 // popAdjustSalary
<template>
    <div class="popAdjustSalary">
        <el-dialog
            :title="dialogTitle"
            :close-on-click-modal="false"
            :visible.sync="dialogVisible"
            :modal-append-to-body="true"
            custom-class="popAdjustSalaryDialog"
            :append-to-body="true"
            width="1360px"
            :before-close="close"
        >
            <el-form
                :model="form"
                ref="form"
                v-loading="loadingDetail"
                :rules="formRules"
                label-position="top"
                :inline="false"
            >
                <div class="baseInfo ofh">
                    <div
                        class="info fll"
                        v-if="dialogTitle.indexOf('查看') !== -1"
                    >
                        <div class="field">
                            姓名：{{
                                form.userName || ""
                            }}
                            &nbsp;&nbsp;|&nbsp;&nbsp;部门：{{
                                form.deptName || ""
                            }}
                            &nbsp;&nbsp;|&nbsp;&nbsp;身份证号码：{{
                                form.idCard || ""
                            }}&nbsp;&nbsp;|&nbsp;&nbsp;
                        </div>
                    </div>
                    <div class="info fll">
                        <div class="field">
                            总工资：{{
                                salaryAll || ""
                            }}
                            &nbsp;&nbsp;|&nbsp;&nbsp;扣减金额：{{
                                deductionTotal || ""
                            }}
                            &nbsp;&nbsp;|&nbsp;&nbsp;五险一金：{{
                                personfiveInsurances || ""
                            }}
                        </div>
                    </div>
                    <el-button
                        type="primary"
                        v-if="salaryOperationType === 'details'"
                        size="default"
                        @click="openpopChangeSalaryHistory"
                        class="controlHisBtn flr"
                        >调薪记录</el-button
                    >
                </div>

                <el-row :gutter="60" v-if="salaryOperationType !== 'details'">
                    <template v-if="salaryOperationType === 'other'">
                        <el-col :span="8">
                            <el-form-item label="姓名" prop="username">
                                <el-input
                                    v-model="form.username"
                                    :disabled="
                                        salaryOperationType === 'details'
                                    "
                                    placeholder="请输入姓名"
                                    clearable
                                ></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="部门" prop="deptName">
                                <el-input
                                    v-model="form.deptName"
                                    :disabled="
                                        salaryOperationType === 'details'
                                    "
                                    placeholder="请输入部门"
                                    clearable
                                ></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item
                                label="手机号/身份证号"
                                prop="telIdcard"
                            >
                                <el-input
                                    v-model="form.telIdcard"
                                    :disabled="
                                        salaryOperationType === 'details'
                                    "
                                    placeholder="请输入手机号/身份证号"
                                    clearable
                                ></el-input>
                            </el-form-item>
                        </el-col>
                    </template>
                    <template v-else>
                        <el-col :span="8">
                            <el-form-item
                                label="生效月份"
                                prop="effectiveMonth"
                            >
                                <el-date-picker
                                    v-model="form.effectiveMonth"
                                    type="month"
                                    value-format="yyyy-MM"
                                    format="yyyy年MM月"
                                    placeholder="选择生效月份"
                                >
                                </el-date-picker>
                            </el-form-item>
                        </el-col>
                    </template>
                </el-row>

                <div class="popItemBox">
                    <el-tabs type="border-card">
                        <el-tab-pane label="工资组成">
                            <el-row :gutter="60">
                                <el-col :span="8">
                                    <el-form-item
                                        label="薪资标准"
                                        prop="salaryStandard"
                                    >
                                        <el-input-money
                                            v-model="form.salaryStandard"
                                            :disabled="
                                                salaryOperationType ===
                                                    'details'
                                            "
                                            placeholder="请输入薪资标准"
                                        ></el-input-money>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="基础工资">
                                        <span slot="label"
                                            >基础工资&nbsp;&nbsp;
                                            <el-tooltip
                                                effect="dark"
                                                content="只是表达薪资的组成方式，不计入统计"
                                            >
                                                <i class="el-icon-warning"></i>
                                            </el-tooltip>
                                        </span>
                                        <el-input-money
                                            v-model="form.salaryBase"
                                            :disabled="
                                                salaryOperationType ===
                                                    'details'
                                            "
                                            placeholder="请输入基础工资"
                                        ></el-input-money>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="岗位工资">
                                        <span slot="label"
                                            >岗位工资&nbsp;&nbsp;
                                            <el-tooltip
                                                effect="dark"
                                                content="只是表达薪资的组成方式，不计入统计"
                                            >
                                                <i class="el-icon-warning"></i>
                                            </el-tooltip>
                                        </span>
                                        <el-input-money
                                            v-model="form.salaryPost"
                                            :disabled="
                                                salaryOperationType ===
                                                    'details'
                                            "
                                            placeholder="请输入岗位工资"
                                        ></el-input-money>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="绩效工资">
                                        <el-input-money
                                            v-model="form.salaryMerit"
                                            :disabled="
                                                salaryOperationType ===
                                                    'details'
                                            "
                                            placeholder="请输入绩效工资"
                                        ></el-input-money>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="补贴">
                                        <el-input-money
                                            v-model="form.subsidy"
                                            :disabled="
                                                salaryOperationType ===
                                                    'details'
                                            "
                                            placeholder="请输入补贴"
                                        ></el-input-money>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="在职延续奖">
                                        <el-input-money
                                            v-model="form.duringBonus"
                                            :disabled="
                                                salaryOperationType ===
                                                    'details'
                                            "
                                            placeholder="请输入在职延续奖"
                                        ></el-input-money>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="餐补">
                                        <el-input-money
                                            v-model="form.salaryFood"
                                            :disabled="
                                                salaryOperationType ===
                                                    'details'
                                            "
                                            placeholder="请输入餐补"
                                        ></el-input-money>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="其他">
                                        <el-input-money
                                            v-model="form.salaryOther"
                                            :disabled="
                                                salaryOperationType ===
                                                    'details'
                                            "
                                            placeholder="请输入其他"
                                        ></el-input-money>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <div class="countBox">
                                <div class="countItem">
                                    <div class="count">
                                        {{ salaryAll || "" }}
                                    </div>
                                    <div class="unit">总工资（元）</div>
                                    <div class="line"></div>
                                </div>
                            </div>
                        </el-tab-pane>
                    </el-tabs>
                </div>

                <div class="popItemBox">
                    <el-tabs type="border-card">
                        <el-tab-pane label="扣减组成">
                            <el-row :gutter="60">
                                <el-col :span="8">
                                    <el-form-item label="其他扣减">
                                        <el-input-money
                                            v-model="form.otherDeductions"
                                            :disabled="
                                                salaryOperationType ===
                                                    'details'
                                            "
                                            placeholder="请输入其他扣减"
                                        ></el-input-money>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="备注">
                                        <el-input
                                            v-model="form.remark"
                                            :disabled="
                                                salaryOperationType ===
                                                    'details'
                                            "
                                            placeholder="请输入备注"
                                        ></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <div class="countBox">
                                <div class="countItem">
                                    <div class="count">
                                        {{ deductionTotal || "" }}
                                    </div>
                                    <div class="unit">总扣减（元）</div>
                                    <div class="line"></div>
                                </div>
                            </div>
                        </el-tab-pane>
                    </el-tabs>
                </div>
                <!-- 五险一金表单 -->
                <com-calcsocial-security
                    ref="comCalcsocialSecurity"
                    :disabled="salaryOperationType === 'details'"
                    :needLoadConfig="needLoadConfig"
                    @changeSecurity="handleChangeSecurity"
                    :formData="form"
                ></com-calcsocial-security>

                <div class="footer" v-if="salaryOperationType !== 'details'">
                    <el-button type="danger" @click="close">取消</el-button>
                    <el-button
                        type="primary"
                        :loading="isSaveLoading"
                        @click="save()"
                        >确定</el-button
                    >
                </div>
            </el-form>
        </el-dialog>

        <!-- 调薪记录 -->
        <pop-change-salary-history
            v-if="popChangeSalaryHistoryVisible"
            :detailParms="detailParms"
            :publicKey="serverPublicKey"
            @close="closepopChangeSalaryHistory"
        ></pop-change-salary-history>
    </div>
</template>

<script>
import PopChangeSalaryHistory from "./popChangeSalaryHistory";
import ComCalcsocialSecurity from "./comCalcsocialSecurity";
import ElInputMoney from "../../../components/form/elInputMoney";
// import SelectAllCompanyPerson from "../../../modleComponents/person/selectAllCompanyPerson";
import { mapGetters } from "vuex";
import API from "@/api/finance.js";
import rsa from "@/utils/encryption/rsa"; // RSA解密
export default {
    name: "popAdjustSalary",

    props: {
        // 查询用id
        id: {
            type: String,
        },

        // 操作类型 新增编辑工资表时  新增/编辑
        operationType: {},

        //操作个人工资表时  新增还是编辑
        salaryOperationType: {},

        // 新增操作时带过来的数据
        salaryRowData: {},

        // 编辑操作时带过来的  id  以及pwd
        detailParms: {},

        // 是否是查询调薪记录详情
        isHistory: {
            type: Boolean,
            default: false,
        },
    },

    components: {
        PopChangeSalaryHistory,
        ComCalcsocialSecurity,
        ElInputMoney,
        // SelectAllCompanyPerson,
    },

    data() {
        return {
            dialogVisible: true,
            isSaveLoading: false,
            loadingDetail: false, //加载详情
            serverPublicKey: "", //后端公钥
            searchForm: {}, //查询详情时需要使用的参数
            form: {
                effectiveMonth: "",
                salaryAll: 0, //总工资
                deductionTotal: 0, //	扣减金额
                fiveInsurances: 0, //五险一金
                personalIncomeTax: 0, //个人所得税
                salary: 0, //实发工资
                userId: "", //用户id
                salaryStandard: "", // 薪资标准
                salaryBase: "", // 基础工资
                salaryPost: "", // 岗位工资
                salaryMerit: "", // 绩效工资
                salaryFood: "", // 餐补
                salaryOther: "", // 其他
                attendanceDeduction: "", // 考勤扣减
                fine: "", // 罚款
                otherDeductions: "", // 其他扣减
                subsidy: "", // 补贴
                duringBonus: "", // 在职延续奖
                remark: "", // 备注
                endowmentInsuranceBase: "", // 养老保险基数
                endowmentInsuranceStaff: "", // 养老保险个人扣
                endowmentInsuranceCompany: "", // 养老保险公司扣
                medicalInsuranceBase: "", // 医疗保险基数
                medicalInsuranceCompany: "", // 医疗保险公司扣
                medicalInsuranceStaff: "", // 医疗保险个人扣
                unemploymentInsuranceBase: "", // 失业保险基数
                unemploymentInsuranceCompany: "", // 失业保险公司扣
                unemploymentInsuranceStaff: "", // 失业保险个人扣
                employmentInjuryInsuranceBase: "", // 工伤保险基数
                employmentInjuryInsuranceCompany: "", // 工伤保险公司扣
                birthInsuranceBase: "", // 生育保险基数
                birthInsuranceCompany: "", // 生育保险公司扣
                accumulationFundBase: "", // 公积金基数
                accumulationFundCompany: Number(0).toFixed(2), // 公积金公司扣
                accumulationFundStaff: Number(0).toFixed(2), // 公积金个人扣
            },
            formRules: {
                userId: {
                    required: true,
                    message: "请选择员工",
                }, // 员工id
                effectiveMonth: {
                    required: true,
                    message: "请选择生效月份",
                }, // 生效月份
                telIdcard: {
                    required: true,
                    message: "请输入手机号/身份证号",
                }, // 手机号/身份证号
            },
            options: [], // 缴税费类别
            detailsInfo: {}, // 详情信息
            editRowIndex: null, // 正在编辑的行
            needApply: false, // 是否需要审核侧边栏
            popSelectTicketVisible: false, // 选择单据弹窗显示
            fiveInsurances: 0, // 五险一金总数
            personfiveInsurances: 0, // 五险一金个人总数
            companyfiveInsurances: 0, // 五险一金单位总数
            // needLoadConfig: true, // 是否需要拉取五险一金
            popChangeSalaryHistoryVisible: false, // 调薪记录弹窗显示

            // 免加密白名单
            notNeedPwd: [
                "deptName",
                "effectiveMonth",
                "id",
                "publicKey",
                "remark",
                "userId",
                "username",
                "version",
                "telIdcard",
                "userName",
                "deptName",
                "idCard",
            ],
        };
    },

    created() {},

    mounted() {},

    methods: {
        // 获取详情
        getSalaryDetails() {
            this.loadingDetail = true;
            API[
                this.isHistory
                    ? "getAdjustSalaryHistoryDetails"
                    : "getSalaryDetails"
            ](
                Object.assign(
                    this.searchForm,
                    this.serverPublicKey
                        ? { publicKey: this.serverPublicKey }
                        : {}, //服务器公钥
                    this.fontRsaKey
                        ? { frontPublicKey: this.fontRsaKey.publicKey }
                        : {}, // 前端生成的RSA 公钥
                    this.searchForm.pwd
                        ? {
                              pwd: this.commonJs.rsaEncryption(
                                  this.salaryPwd,
                                  this.serverPublicKey
                              ),
                          }
                        : {}
                )
            )
                .then((res) => {
                    let content = res.content || {
                        userId: this.detailParms.userId,
                    };
                    // 是否需要重新拉取五险一金
                    this.needLoadConfig = res.content === null ? true : false;
                    let data = Object.assign({}, this.form, content, {});
                    // 免解密白名单
                    let formKeyArr = Object.keys(data);
                    formKeyArr.forEach((item) => {
                        if (this.notNeedPwd.indexOf(item) === -1) {
                            // 需要解密
                            data[item] = rsa.rsaDecrypt(data[item]) || 0;
                        }
                    });
                    this.form = data;
                })
                .finally(() => {
                    this.loadingDetail = false;
                });
        },

        // 解密数据
        entryMap() {
            let nmap = {};
            // for (let i in map) {
            //     // if (map[i].length > 70 && i !== "remark") {
            //     //     nmap[i] = rsa.rsaDecrypt(map[i]) || "****";
            //     // }
            // }
            return nmap;
        },

        // 保存其他人员薪资档案
        save() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.isSaveLoading = true;
                    this.$store
                        .dispatch("GetPublicKey")
                        .then((v) => {
                            this.serverPublicKey = v.content;
                            let data = Object.assign(
                                {},
                                this.form,
                                {
                                    publicKey: this.serverPublicKey,
                                },
                                this.$refs.comCalcsocialSecurity.form
                            );
                            let formKeyArr = Object.keys(data);
                            formKeyArr.forEach((item) => {
                                if (this.notNeedPwd.indexOf(item) === -1) {
                                    // 需要加密
                                    data[item] = this.commonJs.rsaEncryption(
                                        data[item],
                                        this.serverPublicKey
                                    );
                                }
                            });
                            API[
                                this.salaryOperationType === "other"
                                    ? "saveOtherSalary"
                                    : "saveAdjustSalary"
                            ](data)
                                .then((res) => {
                                    //先存库的id
                                    // this.form.id = res.content;
                                    this.$message({
                                        message: res.message || "保存成功",
                                        type: "success",
                                    });
                                    //新增时 的个人工资新增或者编辑的row 一行数据
                                    this.$emit("refresh");
                                    //编辑时
                                    this.$emit("close"); //关闭弹框
                                })
                                .finally(() => {
                                    this.isSaveLoading = false;
                                });
                        })
                        .catch(() => {
                            this.isSaveLoading = false;
                        });
                } else {
                    this.$message({
                        message: "请检查必填内容是否填写！",
                        type: "error",
                    });
                    return;
                }
            });
        },

        // 关闭弹窗
        close() {
            this.$emit("close");
        },

        /*  获取后台公钥  、  生成前端加解密公钥、私钥 */
        entryRsaKey() {
            this.loadingDetail = true;
            this.$store.dispatch("getSalaryKey").then((v) => {
                this.fontRsaKey = v;
                this.$store
                    .dispatch("GetPublicKey")
                    .then((v) => {
                        //后端生成公钥
                        this.serverPublicKey = v.content;
                        this.getSalaryDetails();
                    })
                    .catch(() => {
                        this.loadingDetail = false;
                    });
            });
        },
        // 修改了五险一金表单
        handleChangeSecurity(data) {
            this.$set(this, "form", Object.assign({}, this.form, data));

            //五险一金总数
            this.fiveInsurances =
                (this.$refs.comCalcsocialSecurity &&
                    this.$refs.comCalcsocialSecurity.fiveInsurances) ||
                0;
            //五险一金个人总数
            this.personfiveInsurances =
                (this.$refs.comCalcsocialSecurity &&
                    this.$refs.comCalcsocialSecurity.personfiveInsurances) ||
                0;

            //五险一金单位总数
            this.companyfiveInsurances =
                (this.$refs.comCalcsocialSecurity &&
                    this.$refs.comCalcsocialSecurity.companyfiveInsurances) ||
                0;
        },
        // 调薪记录 打开弹框
        openpopChangeSalaryHistory() {
            this.popChangeSalaryHistoryVisible = true;
        },
        // 调薪记录 关闭弹框
        closepopChangeSalaryHistory() {
            this.popChangeSalaryHistoryVisible = false;
        },
    },

    computed: {
        ...mapGetters(["applyType", "salaryPwd"]),

        // 总工资
        salaryAll() {
            let salaryAll = (
                parseFloat(this.form.salaryStandard) +
                parseFloat(this.form.salaryMerit) +
                parseFloat(this.form.salaryFood) +
                parseFloat(this.form.salaryOther)
            ).toFixed(2);
            return salaryAll;
        },

        //扣减金额
        deductionTotal() {
            let deductionTotal = parseFloat(this.form.otherDeductions).toFixed(
                2
            );
            return deductionTotal;
        },
        // 个人所得税
        personalIncomeTax() {
            return 0;
        },
        //实发工资
        salary() {
            return (
                parseFloat(this.salaryAll) -
                parseFloat(this.personfiveInsurances) -
                parseFloat(this.deductionTotal) -
                parseFloat(this.form.personalIncomeTax)
            ).toFixed(2);
        },

        //个人应发工资  个人收入 - 个人五险一金 - 个人扣款 - 个人所得税
        personSalary() {
            return (
                parseFloat(this.salaryAll) -
                parseFloat(this.personfiveInsurances) -
                parseFloat(this.deductionTotal) -
                parseFloat(this.form.personalIncomeTax)
            ).toFixed(2);
        },

        // 弹窗标题
        dialogTitle() {
            let title =
                this.salaryOperationType === "edit" ? "调整薪资" : "查看薪资";
            if (this.salaryOperationType === "other") {
                title = "新增其他人员";
            }
            return title;
        },
    },

    watch: {
        //新增状态时带来的 id和月份
        salaryRowData: {
            deep: true,
            immediate: true,
            handler: function() {
                setTimeout(() => {
                    // 新增时
                    if (this.salaryOperationType === "add") {
                        // this.form = Object.assign({}, this.form, opt, {
                        //     id: null,
                        // });
                    }
                }, 200);
            },
        },

        /**
         * @描述：编辑操作时的工资表id 以及 查看用户密码
         * @参数: opt: {id:工资表id,pwd:查看工资输入的密码}
         */
        detailParms: {
            deep: true,
            immediate: true,
            handler: function(opt) {
                if (this.isHistory) {
                    this.searchForm = opt;
                    this.getSalaryDetails();
                } else {
                    if (
                        opt &&
                        this.salaryOperationType !== "add" &&
                        this.salaryOperationType !== "other"
                    ) {
                        this.searchForm = opt;
                        this.entryRsaKey();
                    } else {
                        this.form = Object.assign({}, this.form, opt, {
                            id: null,
                        });
                    }
                }
            },
        },
    },
};
</script>

<style lang="scss" scoped>
.baseInfo {
    font-size: 16px;
    padding: 20px 0 40px;
}
.inputUnit {
    height: 100%;
    width: 50px;
    background: rgb(238, 238, 238);
    transform: translateX(10px);
}
.countItem {
    width: 250px;
}
</style>
